// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import {
  countryReducer,
  languageReducer,
  reloadReducer,
  languageTextsReducer,
  confirmReducer,
  roleReducer
} from "../reducers/reducer";

const rootReducer = {
  auth,
  navbar,
  layout,
  countryReducer,
  languageReducer,
  reloadReducer,
  languageTextsReducer,
  confirmReducer,
  roleReducer
};

export default rootReducer;
