//const defaultCountry = "6331da0de58be99b3cb22ee0"; //For dev local
//const defaultLanguage = "6331d9e7e58be99b3cb22edd"; //For dev local
const defaultCountry =
  localStorage.getItem("country") || "62e56c8c51b21c9745567340"; //for server
const defaultLanguage =
  localStorage.getItem("language") || "62e56f6751b21c9745567372"; //for server

export const confirmReducer = (state = null, action) => {
  if (action.type == "setConfirm") {
    return action.payload;
  }

  return state;
};

export const reloadReducer = (state = false, action) => {
  if (action.type == "setReload") {
    return action.payload;
  }

  return state;
};

export const countryReducer = (state = defaultCountry, action) => {
  if (action.type == "setCountry") {
    localStorage.setItem("country", action.payload);
    //window.location = "/";
    return action.payload;
  }

  return state;
};

export const languageReducer = (state = defaultLanguage, action) => {
  if (action.type == "setLanguage") {
    localStorage.setItem("language", action.payload);
    //window.location = "/";
    return action.payload;
  }

  return state;
};

export const languageTextsReducer = (state = {}, action) => {
  if (action.type == "setLanguageTexts") {
    return action.payload;
  }

  return state;
};

export const roleReducer = (state = {}, action) => {
  if (action.type == "setRole") {
    return action.payload;
  }

  return state;
};
