import logo from '@src/assets/images/logo/logo.png'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader'>
      <div className='logo-wrapper'>
        <img
          onError={(e) => {
            e.target.onerror = null
            e.target.src =
              'https://api2.blindlook.com/files/public/photos/defaultPP.svg-19f2fbc3-e879-457b-a700-ba6a8371798cdefaultPP.svg'
          }}
          className='fallback-logo'
          src={logo}
          alt='logo'
        />
        <div className='loading'>
          <div className='effect-1 effects'></div>
          <div className='effect-2 effects'></div>
          <div className='effect-3 effects'></div>
        </div>
      </div>
    </div>
  )
}

export default SpinnerComponent